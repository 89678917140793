/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidirectionalActionType } from './BidirectionalActionType';
import {
    BidirectionalActionTypeFromJSON,
    BidirectionalActionTypeFromJSONTyped,
    BidirectionalActionTypeToJSON,
} from './BidirectionalActionType';

/**
 * 
 * @export
 * @interface BidirectionalActionEnabledItem
 */
export interface BidirectionalActionEnabledItem {
    /**
     * 
     * @type {BidirectionalActionType}
     * @memberof BidirectionalActionEnabledItem
     */
    action: BidirectionalActionType;
    /**
     * 
     * @type {boolean}
     * @memberof BidirectionalActionEnabledItem
     */
    pushes_to_platform: boolean;
}

/**
 * Check if a given object implements the BidirectionalActionEnabledItem interface.
 */
export function instanceOfBidirectionalActionEnabledItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "pushes_to_platform" in value;

    return isInstance;
}

export function BidirectionalActionEnabledItemFromJSON(json: any): BidirectionalActionEnabledItem {
    return BidirectionalActionEnabledItemFromJSONTyped(json, false);
}

export function BidirectionalActionEnabledItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidirectionalActionEnabledItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': BidirectionalActionTypeFromJSON(json['action']),
        'pushes_to_platform': json['pushes_to_platform'],
    };
}

export function BidirectionalActionEnabledItemToJSON(value?: BidirectionalActionEnabledItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': BidirectionalActionTypeToJSON(value.action),
        'pushes_to_platform': value.pushes_to_platform,
    };
}

