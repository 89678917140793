/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionOption } from './QuestionOption';
import {
    QuestionOptionFromJSON,
    QuestionOptionFromJSONTyped,
    QuestionOptionToJSON,
} from './QuestionOption';

/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    group_options_by_category?: boolean;
    /**
     * 
     * @type {Array<QuestionOption>}
     * @memberof Question
     */
    options?: Array<QuestionOption>;
    /**
     * 
     * @type {any}
     * @memberof Question
     */
    internal_options?: any | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Question
     */
    internal_option_to_platform_option_map?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    required?: boolean;
}

/**
 * Check if a given object implements the Question interface.
 */
export function instanceOfQuestion(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QuestionFromJSON(json: any): Question {
    return QuestionFromJSONTyped(json, false);
}

export function QuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Question {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'group_options_by_category': !exists(json, 'group_options_by_category') ? undefined : json['group_options_by_category'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(QuestionOptionFromJSON)),
        'internal_options': !exists(json, 'internal_options') ? undefined : json['internal_options'],
        'internal_option_to_platform_option_map': !exists(json, 'internal_option_to_platform_option_map') ? undefined : json['internal_option_to_platform_option_map'],
        'required': !exists(json, 'required') ? undefined : json['required'],
    };
}

export function QuestionToJSON(value?: Question | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'group_options_by_category': value.group_options_by_category,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(QuestionOptionToJSON)),
        'internal_options': value.internal_options,
        'internal_option_to_platform_option_map': value.internal_option_to_platform_option_map,
        'required': value.required,
    };
}

