/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LeadConnectorEnum = {
    Aida: 'Aida',
    Aidin: 'Aidin',
    Ensocare: 'Ensocare',
    EnsocareVa: 'Ensocare VA',
    EpicCareLink: 'EpicCare Link',
    EpicCareLinkAllinaHealth: 'EpicCare Link Allina Health',
    EpicCareLinkLegacyHealthyPlanet: 'EpicCare Link Legacy Healthy Planet',
    EpicCareLinkPiedmont: 'EpicCare Link Piedmont',
    EpicCareLinkSsmHealth: 'EpicCare Link SSM Health',
    Fax: 'Fax',
    ManualUpload: 'Manual Upload',
    WellSkyIntake: 'WellSky Intake',
    WellSkyReferralManagement: 'WellSky Referral Management',
    WellSkyReferralManagementWebReferral: 'WellSky Referral Management Web Referral'
} as const;
export type LeadConnectorEnum = typeof LeadConnectorEnum[keyof typeof LeadConnectorEnum];


export function LeadConnectorEnumFromJSON(json: any): LeadConnectorEnum {
    return LeadConnectorEnumFromJSONTyped(json, false);
}

export function LeadConnectorEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadConnectorEnum {
    return json as LeadConnectorEnum;
}

export function LeadConnectorEnumToJSON(value?: LeadConnectorEnum | null): any {
    return value as any;
}

