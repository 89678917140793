/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadFacility
 */
export interface LeadFacility {
    /**
     * 
     * @type {string}
     * @memberof LeadFacility
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeadFacility
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadFacility
     */
    facility_type?: LeadFacilityFacilityTypeEnum;
}


/**
 * @export
 */
export const LeadFacilityFacilityTypeEnum = {
    SkilledNursing: 'SKILLED_NURSING',
    CriticalCare: 'CRITICAL_CARE',
    HomeHealth: 'HOME_HEALTH',
    AssistedLiving: 'ASSISTED_LIVING'
} as const;
export type LeadFacilityFacilityTypeEnum = typeof LeadFacilityFacilityTypeEnum[keyof typeof LeadFacilityFacilityTypeEnum];


/**
 * Check if a given object implements the LeadFacility interface.
 */
export function instanceOfLeadFacility(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function LeadFacilityFromJSON(json: any): LeadFacility {
    return LeadFacilityFromJSONTyped(json, false);
}

export function LeadFacilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadFacility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'facility_type': !exists(json, 'facility_type') ? undefined : json['facility_type'],
    };
}

export function LeadFacilityToJSON(value?: LeadFacility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'facility_type': value.facility_type,
    };
}

