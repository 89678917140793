/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BidirectionalActionType,
  ConnectorType,
  CreateHospitalMessage200Response,
  FindAllHospitalMessages200Response,
  GetBiDirectionalConfig200Response,
  HospitalMessage,
  PullHospitalMessages200Response,
} from '../models/index';
import {
    BidirectionalActionTypeFromJSON,
    BidirectionalActionTypeToJSON,
    ConnectorTypeFromJSON,
    ConnectorTypeToJSON,
    CreateHospitalMessage200ResponseFromJSON,
    CreateHospitalMessage200ResponseToJSON,
    FindAllHospitalMessages200ResponseFromJSON,
    FindAllHospitalMessages200ResponseToJSON,
    GetBiDirectionalConfig200ResponseFromJSON,
    GetBiDirectionalConfig200ResponseToJSON,
    HospitalMessageFromJSON,
    HospitalMessageToJSON,
    PullHospitalMessages200ResponseFromJSON,
    PullHospitalMessages200ResponseToJSON,
} from '../models/index';

export interface CreateHospitalMessageRequest {
    leadId: string;
    hospitalMessage: HospitalMessage;
}

export interface FindAllHospitalMessagesRequest {
    leadId: string;
}

export interface GetBiDirectionalConfigRequest {
    connector: ConnectorType;
    action: BidirectionalActionType;
    facilityId: string;
    facilityType?: GetBiDirectionalConfigFacilityTypeEnum;
}

export interface PullHospitalMessagesRequest {
    leadId: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create a hospital message
     * Create a hospital message
     */
    async createHospitalMessageRaw(requestParameters: CreateHospitalMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateHospitalMessage200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling createHospitalMessage.');
        }

        if (requestParameters.hospitalMessage === null || requestParameters.hospitalMessage === undefined) {
            throw new runtime.RequiredError('hospitalMessage','Required parameter requestParameters.hospitalMessage was null or undefined when calling createHospitalMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/{lead_id}/hospital-messages`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HospitalMessageToJSON(requestParameters.hospitalMessage),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateHospitalMessage200ResponseFromJSON(jsonValue));
    }

    /**
     * Create a hospital message
     * Create a hospital message
     */
    async createHospitalMessage(requestParameters: CreateHospitalMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateHospitalMessage200Response> {
        const response = await this.createHospitalMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all hospital messages for a lead
     * Get all hospital messages for a lead
     */
    async findAllHospitalMessagesRaw(requestParameters: FindAllHospitalMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllHospitalMessages200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling findAllHospitalMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/hospital-messages`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllHospitalMessages200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all hospital messages for a lead
     * Get all hospital messages for a lead
     */
    async findAllHospitalMessages(requestParameters: FindAllHospitalMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllHospitalMessages200Response> {
        const response = await this.findAllHospitalMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get bidirectional config
     * Get bidirectional config
     */
    async getBiDirectionalConfigRaw(requestParameters: GetBiDirectionalConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBiDirectionalConfig200Response>> {
        if (requestParameters.connector === null || requestParameters.connector === undefined) {
            throw new runtime.RequiredError('connector','Required parameter requestParameters.connector was null or undefined when calling getBiDirectionalConfig.');
        }

        if (requestParameters.action === null || requestParameters.action === undefined) {
            throw new runtime.RequiredError('action','Required parameter requestParameters.action was null or undefined when calling getBiDirectionalConfig.');
        }

        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getBiDirectionalConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.connector !== undefined) {
            queryParameters['connector'] = requestParameters.connector;
        }

        if (requestParameters.action !== undefined) {
            queryParameters['action'] = requestParameters.action;
        }

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.facilityType !== undefined) {
            queryParameters['facility_type'] = requestParameters.facilityType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/integrations/bidirectional-config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBiDirectionalConfig200ResponseFromJSON(jsonValue));
    }

    /**
     * Get bidirectional config
     * Get bidirectional config
     */
    async getBiDirectionalConfig(requestParameters: GetBiDirectionalConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBiDirectionalConfig200Response> {
        const response = await this.getBiDirectionalConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger a pull of hospital messages
     * Trigger a pull of hospital messages
     */
    async pullHospitalMessagesRaw(requestParameters: PullHospitalMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PullHospitalMessages200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling pullHospitalMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/hospital-messages/pull`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PullHospitalMessages200ResponseFromJSON(jsonValue));
    }

    /**
     * Trigger a pull of hospital messages
     * Trigger a pull of hospital messages
     */
    async pullHospitalMessages(requestParameters: PullHospitalMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PullHospitalMessages200Response> {
        const response = await this.pullHospitalMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetBiDirectionalConfigFacilityTypeEnum = {
    SkilledNursing: 'SKILLED_NURSING',
    CriticalCare: 'CRITICAL_CARE',
    HomeHealth: 'HOME_HEALTH',
    AssistedLiving: 'ASSISTED_LIVING'
} as const;
export type GetBiDirectionalConfigFacilityTypeEnum = typeof GetBiDirectionalConfigFacilityTypeEnum[keyof typeof GetBiDirectionalConfigFacilityTypeEnum];
