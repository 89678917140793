/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BidirectionalActionConfigDate
 */
export interface BidirectionalActionConfigDate {
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionConfigDate
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof BidirectionalActionConfigDate
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BidirectionalActionConfigDate
     */
    disable_past?: boolean;
}

/**
 * Check if a given object implements the BidirectionalActionConfigDate interface.
 */
export function instanceOfBidirectionalActionConfigDate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "required" in value;

    return isInstance;
}

export function BidirectionalActionConfigDateFromJSON(json: any): BidirectionalActionConfigDate {
    return BidirectionalActionConfigDateFromJSONTyped(json, false);
}

export function BidirectionalActionConfigDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidirectionalActionConfigDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'required': json['required'],
        'disable_past': !exists(json, 'disable_past') ? undefined : json['disable_past'],
    };
}

export function BidirectionalActionConfigDateToJSON(value?: BidirectionalActionConfigDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'required': value.required,
        'disable_past': value.disable_past,
    };
}

