import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  Typography
} from '@mui/material';

import { hasNA } from './ControlledSelect';

export function ControlledSelectPrimitive<
  TFields extends FieldValues,
  Option extends Record<any, any> | string | symbol | number
>({
  name,
  control,
  rules,
  options,
  optionLabelKey,
  optionDescriptionKey,
  optionIdKey,
  label,
  isLoading,
  hideNA = false,
  onChange,
  helperText,
  ...props
}: React.PropsWithChildren<
  UseControllerProps<TFields> &
    Partial<SelectProps> & {
      options?: Option[];
      optionLabelKey?: keyof Option;
      optionDescriptionKey?: keyof Option;
      optionIdKey?: keyof Option;
      hideNA?: boolean;
      isLoading?: boolean;
      onChange?: (id: string) => void;
      helperText?: string;
    }
>) {
  const {
    field: { ref, onChange: onChangeField, value, ...fields },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });
  if (
    !props.renderValue &&
    Array.isArray(options) &&
    options.length == 0 &&
    isLoading
  ) {
    props.renderValue = () => (
      <CircularProgress sx={{ mb: -0.5, ml: 0.5 }} size="1rem" thickness={5} />
    );
  }

  const { size } = { ...props };

  const renderLabel = () => {
    if (rules?.required && label) {
      return (
        <span>
          {label}
          <span style={{ color: '#DD3730' }}>*</span>
        </span>
      );
    } else if (label) {
      return label;
    } else {
      return '';
    }
  };

  return (
    <FormControl
      size={size}
      error={!!error}
      fullWidth={props.fullWidth ?? true}>
      <InputLabel>{renderLabel()}</InputLabel>
      {isLoading && (
        <Select
          label={`${label ? label : ''}${rules?.required && label ? '*' : ''}`}
          value={' '}
          inputRef={ref}
          {...fields}
          {...props}
          disabled
          renderValue={() => (
            <CircularProgress
              sx={{ mb: -0.5, ml: 0.5 }}
              size="1rem"
              thickness={5}
            />
          )}
        />
      )}
      {!isLoading && (
        <Select
          label={renderLabel()}
          value={value ?? ''}
          onChange={(e) => {
            const id = e.target.value;
            onChangeField(id);
            onChange?.(id as any);
          }}
          inputRef={ref}
          {...fields}
          {...props}
          renderValue={(selected) => {
            const selectedOption = Array.isArray(options)
              ? options.find(
                  (option) => (option?.[optionIdKey] ?? option) === selected
                )
              : undefined;
            if (!selectedOption) {
              return '';
            }
            let selectedLabel =
              selectedOption[optionLabelKey] ?? selectedOption;
            if (typeof selectedLabel === 'function') {
              selectedLabel = selectedLabel();
            }
            return selectedLabel as string;
          }}>
          {rules?.required && (
            <MenuItem disabled hidden value="" sx={{ display: 'none' }} />
          )}
          {!hideNA && !rules?.required && !hasNA(options) && (
            <MenuItem value="">
              <em>N/A</em>
            </MenuItem>
          )}
          {options?.map((option) => {
            const key = option?.[optionIdKey] ?? option;
            const optionValue = option?.[optionIdKey] ?? option;
            const optionDescription = option?.[optionDescriptionKey] ?? option;
            let optionDescriptionLabel = optionDescription;
            let optionLabel = option?.[optionLabelKey] ?? option;
            if (typeof optionLabel === 'function') {
              optionLabel = optionLabel();
            }
            if (typeof optionDescriptionLabel === 'function') {
              optionDescriptionLabel = optionDescriptionLabel();
            }
            return (
              <MenuItem
                key={key as string}
                value={optionValue as string}
                data-testid={`mui-component-select-${String(name)}-option`}
                id={`mui-component-select-${String(name)}-option-${String(
                  key
                )}`}>
                <Stack direction="column">
                  {optionLabel as string}
                  {optionDescriptionKey && optionDescriptionLabel && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        whiteSpace: 'normal',
                        maxWidth: '250px'
                      }}>
                      {optionDescriptionLabel as string}
                    </Typography>
                  )}
                </Stack>
              </MenuItem>
            );
          })}
        </Select>
      )}
      <FormHelperText>{helperText || error?.message}</FormHelperText>
    </FormControl>
  );
}
