/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadAdmissionStatusInfo
 */
export interface LeadAdmissionStatusInfo {
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    booked_by?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    booked_at?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    target_move_in_date?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadAdmissionStatusInfo
     */
    move_in_date?: string;
}

/**
 * Check if a given object implements the LeadAdmissionStatusInfo interface.
 */
export function instanceOfLeadAdmissionStatusInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LeadAdmissionStatusInfoFromJSON(json: any): LeadAdmissionStatusInfo {
    return LeadAdmissionStatusInfoFromJSONTyped(json, false);
}

export function LeadAdmissionStatusInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadAdmissionStatusInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'booked_by': !exists(json, 'booked_by') ? undefined : json['booked_by'],
        'booked_at': !exists(json, 'booked_at') ? undefined : json['booked_at'],
        'target_move_in_date': !exists(json, 'target_move_in_date') ? undefined : json['target_move_in_date'],
        'move_in_date': !exists(json, 'move_in_date') ? undefined : json['move_in_date'],
    };
}

export function LeadAdmissionStatusInfoToJSON(value?: LeadAdmissionStatusInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
        'comment': value.comment,
        'booked_by': value.booked_by,
        'booked_at': value.booked_at,
        'target_move_in_date': value.target_move_in_date,
        'move_in_date': value.move_in_date,
    };
}

