import React from 'react';
import { Chip, ChipProps } from '@mui/material';

type StaffLoginChipProps = {
  label?: string;
  size?: 'small' | 'medium';
} & ChipProps;

const StaffLoginChip = ({
  label = 'Not logged in yet',
  size = 'small',
  sx,
  ...props
}: StaffLoginChipProps) => {
  return (
    <Chip
      label={label}
      size={size}
      sx={{
        backgroundColor: '#ED6C02',
        padding: 0,
        width: 'max-content',
        '& .MuiChip-label': {
          color: 'white !important'
        },
        ...sx
      }}
      {...props}
    />
  );
};

export default StaffLoginChip;
