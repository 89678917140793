/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AdmissionStatus = {
    Booked: 'Booked',
    MovedIn: 'Moved-In',
    Lost: 'Lost'
} as const;
export type AdmissionStatus = typeof AdmissionStatus[keyof typeof AdmissionStatus];


export function AdmissionStatusFromJSON(json: any): AdmissionStatus {
    return AdmissionStatusFromJSONTyped(json, false);
}

export function AdmissionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdmissionStatus {
    return json as AdmissionStatus;
}

export function AdmissionStatusToJSON(value?: AdmissionStatus | null): any {
    return value as any;
}

