import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

import { queryClient } from '@/adapters/query';
import { usersv2Client } from '@/adapters/schemaClients';
import { USERS_FIND_ONE_QUERY_KEY } from '@/hooks/useUserQuery';
import {
  DynamicPermission,
  FindAllUserDynamicPermissionsRequest,
  SetUserDynamicPermissionsOperationRequest
} from '@/types/users';

export enum UserDynamicPermissions {
  BILLING_AND_PRODUCT_PRICING = 'BILLING_AND_PRODUCT_PRICING',
  ASSESSMENT_REVIEW_AND_CARE_SUBSCRIPTIONS = 'ASSESSMENT_REVIEW_AND_CARE_SUBSCRIPTIONS',
  CRM_GENERAL = 'CRM_GENERAL',
  DATA_CENTER_GENERAL = 'DATA_CENTER_GENERAL',
  WRITE_SYSTEM_PERMISSIONS = 'WRITE_SYSTEM_PERMISSIONS',
  CRM_SCREENER_RULES_AND_DATA_PULLS = 'CRM_SCREENER_RULES_AND_DATA_PULLS',
  EDIT_USER_TYPE_NOTIFICATION_PREFERENCES = 'EDIT_USER_TYPE_NOTIFICATION_PREFERENCES'
}

const FIND_ALL_USER_DYNAMIC_PERMISSIONS_QUERY_KEY =
  'FIND_ALL_USER_DYNAMIC_PERMISSIONS_QUERY_KEY';

export const useDynamicPermissionQuery = () => {
  const invalidate = () => {
    queryClient.invalidateQueries([
      FIND_ALL_USER_DYNAMIC_PERMISSIONS_QUERY_KEY
    ]);
  };
  return {
    invalidate,

    findAllUserDynamicPermissions: (
      params?: FindAllUserDynamicPermissionsRequest,
      options: UseQueryOptions<DynamicPermission[], HTTPError> = {}
    ) =>
      useQuery<DynamicPermission[], HTTPError>(
        [FIND_ALL_USER_DYNAMIC_PERMISSIONS_QUERY_KEY, params],
        async () => {
          const response = await usersv2Client.findAllUserDynamicPermissions(
            params!
          );
          return response.data || [];
        },
        {
          enabled: !!params?.id,
          ...options
        }
      ),
    mutations: {
      setUserDynamicPermissions: useMutation(
        (params: SetUserDynamicPermissionsOperationRequest) => {
          return usersv2Client.setUserDynamicPermissions(params);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              FIND_ALL_USER_DYNAMIC_PERMISSIONS_QUERY_KEY
            ]);
            queryClient.invalidateQueries([USERS_FIND_ONE_QUERY_KEY]);
          }
        }
      )
    }
  };
};
