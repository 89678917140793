/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PccPatientCarePeriod
 */
export interface PccPatientCarePeriod {
    /**
     * 
     * @type {string}
     * @memberof PccPatientCarePeriod
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCarePeriod
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCarePeriod
     */
    end_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCarePeriod
     */
    period_type?: PccPatientCarePeriodPeriodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PccPatientCarePeriod
     */
    pcc_care_period_id?: string;
}


/**
 * @export
 */
export const PccPatientCarePeriodPeriodTypeEnum = {
    Encounter: 'ENCOUNTER',
    PatientHistory: 'PATIENT_HISTORY'
} as const;
export type PccPatientCarePeriodPeriodTypeEnum = typeof PccPatientCarePeriodPeriodTypeEnum[keyof typeof PccPatientCarePeriodPeriodTypeEnum];


/**
 * Check if a given object implements the PccPatientCarePeriod interface.
 */
export function instanceOfPccPatientCarePeriod(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PccPatientCarePeriodFromJSON(json: any): PccPatientCarePeriod {
    return PccPatientCarePeriodFromJSONTyped(json, false);
}

export function PccPatientCarePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccPatientCarePeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'start_date': !exists(json, 'start_date') ? undefined : json['start_date'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
        'period_type': !exists(json, 'period_type') ? undefined : json['period_type'],
        'pcc_care_period_id': !exists(json, 'pcc_care_period_id') ? undefined : json['pcc_care_period_id'],
    };
}

export function PccPatientCarePeriodToJSON(value?: PccPatientCarePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'start_date': value.start_date,
        'end_date': value.end_date,
        'period_type': value.period_type,
        'pcc_care_period_id': value.pcc_care_period_id,
    };
}

