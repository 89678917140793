/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BidirectionalActionConfigTextMessage
 */
export interface BidirectionalActionConfigTextMessage {
    /**
     * 
     * @type {boolean}
     * @memberof BidirectionalActionConfigTextMessage
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BidirectionalActionConfigTextMessage
     */
    required: boolean;
    /**
     * 
     * @type {number}
     * @memberof BidirectionalActionConfigTextMessage
     */
    max_length?: number;
}

/**
 * Check if a given object implements the BidirectionalActionConfigTextMessage interface.
 */
export function instanceOfBidirectionalActionConfigTextMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "required" in value;

    return isInstance;
}

export function BidirectionalActionConfigTextMessageFromJSON(json: any): BidirectionalActionConfigTextMessage {
    return BidirectionalActionConfigTextMessageFromJSONTyped(json, false);
}

export function BidirectionalActionConfigTextMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidirectionalActionConfigTextMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'required': json['required'],
        'max_length': !exists(json, 'max_length') ? undefined : json['max_length'],
    };
}

export function BidirectionalActionConfigTextMessageToJSON(value?: BidirectionalActionConfigTextMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'required': value.required,
        'max_length': value.max_length,
    };
}

