/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidirectionalActionDetailsSelections } from './BidirectionalActionDetailsSelections';
import {
    BidirectionalActionDetailsSelectionsFromJSON,
    BidirectionalActionDetailsSelectionsFromJSONTyped,
    BidirectionalActionDetailsSelectionsToJSON,
} from './BidirectionalActionDetailsSelections';

/**
 * 
 * @export
 * @interface BidirectionalActionDetailsDetails
 */
export interface BidirectionalActionDetailsDetails {
    /**
     * 
     * @type {Array<BidirectionalActionDetailsSelections>}
     * @memberof BidirectionalActionDetailsDetails
     */
    selections: Array<BidirectionalActionDetailsSelections>;
    /**
     * 
     * @type {any}
     * @memberof BidirectionalActionDetailsDetails
     */
    internal_selections?: any | null;
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetailsDetails
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetailsDetails
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetailsDetails
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetailsDetails
     */
    internal_message?: string;
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetailsDetails
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof BidirectionalActionDetailsDetails
     */
    phone_number_extension?: string;
}

/**
 * Check if a given object implements the BidirectionalActionDetailsDetails interface.
 */
export function instanceOfBidirectionalActionDetailsDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "selections" in value;

    return isInstance;
}

export function BidirectionalActionDetailsDetailsFromJSON(json: any): BidirectionalActionDetailsDetails {
    return BidirectionalActionDetailsDetailsFromJSONTyped(json, false);
}

export function BidirectionalActionDetailsDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidirectionalActionDetailsDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selections': ((json['selections'] as Array<any>).map(BidirectionalActionDetailsSelectionsFromJSON)),
        'internal_selections': !exists(json, 'internal_selections') ? undefined : json['internal_selections'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'internal_message': !exists(json, 'internal_message') ? undefined : json['internal_message'],
        'phone_number': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'phone_number_extension': !exists(json, 'phone_number_extension') ? undefined : json['phone_number_extension'],
    };
}

export function BidirectionalActionDetailsDetailsToJSON(value?: BidirectionalActionDetailsDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selections': ((value.selections as Array<any>).map(BidirectionalActionDetailsSelectionsToJSON)),
        'internal_selections': value.internal_selections,
        'date': value.date,
        'time': value.time,
        'message': value.message,
        'internal_message': value.internal_message,
        'phone_number': value.phone_number,
        'phone_number_extension': value.phone_number_extension,
    };
}

